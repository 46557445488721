import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import { languages } from '../../utils/languages';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
  },
}));

const LanguagePickerContainer = styled.div`
  position: relative;
  display: inline-block;

  .sticky-nav-active & .icon-button {
    color: #000000; // Change to black or any desired color when sticky
  }

  .icon-button {
    color: #ffffff; // Default color (white)
    padding: 8px; // Override padding to 8px
  }
`;

const LanguagePicker = ({ pageContext }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleLanguageChange = (languageCode) => {
    const newLang = languages[languageCode];
    if (newLang) {
      const newPrefix = newLang.code === 'en' ? '' : `/${newLang.code}`;
      const currentPath =
        window.location.pathname.replace(
          pageContext.langPathPrefix,
          newPrefix
        ) || '/';
      const newUrl = `${currentPath}${window.location.search}${window.location.hash}`;

      // Use a full redirect to change the language while preserving the anchor
      window.location.href = newUrl;
    }
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const activeLanguages = Object.values(languages).filter(
    (lang) => lang.active
  );

  return (
    <LanguagePickerContainer className={classes.root}>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="icon-button"
      >
        <LanguageIcon fontSize="small" />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {activeLanguages.map((lang) => (
                    <MenuItem
                      key={lang.code}
                      onClick={() => handleLanguageChange(lang.code)}
                    >
                      {lang.lng_title_native}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </LanguagePickerContainer>
  );
};

export default LanguagePicker;
